import React from "react"

import Section from "../../components/Section/Section"
import Title from "../../components/Title/Title"

import "./Privacy.scss"

const Privacy = props => (
  <Section id="Privacy" className="Privacy">
    <Title title="PRIVACY POLICY" text="プライバシーポリシー" />
    <div className="Privacy__text">
      <p>プライバシーポリシー（個人情報保護方針）</p>
      <p>株式会社あんにん（以下、「当社」という。）は，ユーザーの個人情報について以下のとおりプライバシーポリシー（以下、「本ポリシー」という。）を定めます。本ポリシーは、当社がどのような個人情報を取得し、どのように利用・共有するか、ユーザーがどのようにご自身の個人情報を管理できるかをご説明するものです。</p>
      <ol>
        <li>事業者情報法人名：株式会社あんにん住所：神奈川県横浜市西区南幸2-20-15-201代表者：倉島杏梨</li>
        <li>個人情報の取得方法当社は、ユーザーが利用登録をするとき、氏名・生年月日・住所・電話番号・メールアドレス・クレジットカード番号など個人を特定できる情報を取得させていただきます。お問い合わせフォーム・コメントの送信時には、氏名・電話番号・メールアドレスを取得させていただきます。</li>
        <li>個人情報の利用目的
          <ul>
            <li>取得した閲覧・購買履歴等の情報を分析し、ウェブマーケティング事業及びウェブプロモーション事業、デジタルコンテンツの販売、EC (電子商取引) サイトの企画、制作、運営及び管理、各種出版物の企画、制作及び販売、各種メディアの運用及びその代行、並びに各種コンサルティング事業においてユーザーに適した新商品・サービスをお知らせするために利用します。また、取得した閲覧・購買履歴等の情報は、結果をスコア化した上で当該スコアを第三者へ提供します</li>
            <li>ユーザーが利用しているサービスの新機能や更新情報、キャンペーン情報などをメール送付によりご案内するため</li>
            <li>ユーザーが利用しているサービスのメンテナンスなど、必要に応じたご連絡をするため</li>
            <li>ユーザーからのコメントやお問い合わせに回答するため</li>
            <li>利用規約に違反したユーザーの特定、その他不正不当な目的でサービスを利用したユーザーの特定をし、ご利用をお断りするため</li>
            <li>有料プランを利用しているユーザーに対して利用料金をご請求するため</li>
          </ul>
        個人情報の利用目的は、変更前後の関連性について合理性が認められる場合に限って変更するものとします。個人情報の利用目的について変更を行った際は、変更後の目的について当社所定の方法によってユーザーに通知し、加えてWebサイト上にも公表するものとします。</li>
        <li>個人データを安全に管理するための措置当社は、個人情報を正確かつ最新の内容に保つよう努め、不正なアクセス・改ざん・漏えい・滅失及び毀損から保護するため全従業員及び役員に対して教育研修を実施しています。また、個人情報保護規定を設け、現場での管理についても定期的な点検を行っています。</li>
        <li>個人データの共同利用当社は、共同利用を行いません。</li>
        <li>個人データの第三者提供について当社は以下の場合を除き、同意を得ないで第三者に個人情報を提供することは致しません。
          <ul>
            <li>法令に基づく場合</li>
            <li>人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
            <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
            <li>国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
            <li>次に掲げる事項をあらかじめ本人に通知または公表し、かつ当社が個人情報保護委員会に届出をしたとき
              <ol>
                <li>第三者への提供を利用目的とすること</li>
                <li>第三者に提供される個人データの項目</li>
                <li>第三者への提供の方法</li>
                <li>本人の求めに応じて当該個人情報の第三者への提供を停止すること</li>
                <li>本人の求めを受け付ける方法</li>
              </ol>
            </li>
          </ul>
        </li>
        <li>匿名加工情報に関する取扱い当社は、匿名加工情報（特定の個人を識別できないよう加工した個人情報であって、復元ができないようにしたもの）を作成する場合、以下の対応を行います。
          <ul>
            <li>法令で定める基準に従い適正な加工を施す</li>
            <li>法令で定める基準に従い安全管理措置を講じる</li>
            <li>匿名加工情報に含まれる個人に関する情報の項目を公表する</li>
            <li>作成元となった個人情報の本人を識別するため、他の情報と照合すること</li>
          </ul>
        </li>
        <li>保有個人データの開示、訂正当社は、本人から個人情報の開示を求められたときには、遅滞なく本人に対しこれを開示します。個人情報の利用目的の通知や訂正、追加、削除、利用の停止、第三者への提供の停止を希望される方は、以下の手続きに従ってご請求ください。<br />以下の書類等をお問い合わせ窓口宛てにご郵送願います。
          <ul>
            <li>保有個人データ開示等請求書</li>
            <li>本人確認書類の写し（運転免許証、個人番号カード、在留カード、特別永住者証明書、パスポートなどの顔写真付き証明書）</li>
            <li>手数料（事務手数料として1請求につき500円）</li>
          </ul>
        送付先住所〒220-0005神奈川県横浜市西区南幸2-20-15-201株式会社あんにん お問い合わせ窓口</li>
        <li>個人情報取扱いに関する相談や苦情の連絡先当社の個人情報の取扱いに関するご質問やご不明点、苦情、その他のお問い合わせはお問い合わせフォームよりご連絡ください。</li>
        <li>SSL（Secure Socket Layer）について当社のWebサイトはSSLに対応しており、WebブラウザとWebサーバーとの通信を暗号化しています。ユーザーが入力する氏名や住所、電話番号などの個人情報は自動的に暗号化されます。</li>
        <li>cookieについてcookieとは、WebサーバーからWebブラウザに送信されるデータのことです。Webサーバーがcookieを参照することでユーザーのパソコンを識別でき、効率的に当社Webサイトを利用することができます。当社Webサイトがcookieとして送るファイルは、個人を特定するような情報を含んでおりません。お使いのWebブラウザの設定により、cookieを無効にすることも可能です。</li>
        <li>プライバシーポリシーの制定日及び改定日制定：2022年3月1日</li>
        <li>免責事項
          <ul>
            <li>当社Webサイトに掲載されている情報の正確さには万全を期していますが、利用者が当社Webサイトの情報を用いて行う一切の行為に関して、当社は一切の責任を負わないものとします。</li>
            <li>当社は、利用者が当社Webサイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものとします。</li>
          </ul>
        </li>
        <li>著作権・肖像権当社Webサイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されており、無断での使用や転用は禁止されています。</li>
        <li>リンク当社Webサイトへのリンクは、自由に設置していただいて構いません。ただし、Webサイトの内容等によってはリンク設置をお断りすることがあります。</li>
      </ol>
    </div>
  </Section>
)

export default Privacy

import React from "react"

import Seo from "../components/seo"

import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import Privacy from "../modules/Privacy/Privacy"

import "../assets/reset.css"
import "../assets/base.scss"

const PrivacyPage = () => (
  <>
    <Seo title="プライバシーポリシー | ANIN Co." />
    <Header />
    <Privacy />
    <Footer />
  </>
)

export default PrivacyPage
